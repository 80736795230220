<template>
  <div class="clinic">
    <!-- 表单 -->
  <el-form inline class="form-content">
      <el-form-item>
     
		<el-input placeholder="请输入关键词查询" v-model="search" style="width: 330px;" class="input-with-select ">
		  <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 100px;">
		  <el-option
		    label="姓名"
		    :value="1"
		  ></el-option>
		  <el-option
		    label="手机号"
		    :value="2"
		  ></el-option>
		  </el-select>
		  <el-button slot="append" icon="el-icon-search" @click="getDataList"></el-button>
		</el-input>
      </el-form-item>
      <el-form-item label="下次随访">
         <el-radio-group v-model="nextDate">
              <el-radio-button :value="3" label="3">3天</el-radio-button>
              <el-radio-button :value="7" label="7">7天</el-radio-button>
              <el-radio-button :value="15" label="15">15天</el-radio-button>
              <el-radio-button :value="30" label="30">30天</el-radio-button>
            </el-radio-group>
      </el-form-item>
      <el-form-item label="自定义">
        <el-date-picker
		style="width: 150px;"
          v-model="startTime"
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></el-date-picker>
		——
		<el-date-picker
			style="width: 150px;"
		  v-model="endTime"
		  type="datetime"
		  placeholder="选择日期"
		  value-format="yyyy-MM-dd"
		  format="yyyy-MM-dd"
		></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="reset()" type="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="search-list">
       <el-button type="primary" @click="exportList">导出</el-button>
   
      
    </div>
    
    <!-- 发送方案 -->
    <el-table
	       v-loading="dataListLoading"
      ref="multipleTable"
      :data="dataList"
      @selection-change="selectionChangeHandle"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="姓名" prop="patienceName"></el-table-column>
      <el-table-column prop="patience_sex_name" label="性别"></el-table-column>
      <el-table-column prop="patience_age" label="年龄"></el-table-column>
      <el-table-column prop="patienceLinkTel" label="联系方式"></el-table-column>
      <el-table-column prop="mainDiagnosisName" label="主要诊断"></el-table-column>
      <el-table-column prop="initialTime" label="初次就诊时间"></el-table-column>
	   <el-table-column prop="lastTime" label="上次随访日期"></el-table-column>
	    <el-table-column prop="number" label="随访次数"></el-table-column>
		 <el-table-column prop="nextDate" label="下次随访日期"></el-table-column>
		 <el-table-column  label="距离下次随访">
			 <template slot-scope="scope">
			 {{scope.row.nextDay}}天
			 </template>
		 </el-table-column>
		           
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>

<script>
// import { getTeamsPatienceListApi } from "@/api/case";
import moment from "moment";
import { idCardRegExp, idCardFunction,baseURL } from "@/utils";

export default {
  components: {

  },
  data() {
    return {
      sendPackageDialog: false, //发送方案

      dataListSelections: [],
      currentPage4: 5,
      input3: "",
      form: {
        patienceName: "",

        patienceSex: "",

        mainDiagnosis: "",
      },
	  "startTime":'',
	  "endTime":'',
      typeArr: [],
      tableData: [],
     nextDate:'',
	  search:'',
	  select:1,
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false, //列表loading
      tabData: [], //备用列表，在调用数据时使用
      dataList: [], //列表数据
      oldList: [],
      dateTime: ["", ""],
    };
  },
  mounted() {
    this.getDataList();
 //   this.getPorjType();
  },
  methods: {
    // async getDataList() {
    //   const res = await getTeamsPatienceListApi(this.params);
    //   console.log(res)
    // },
    // 发送方案弹窗

    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(`/teamsPatience/delete?ids=${id}`),
            method: "post",
			  data: { stringParam1: id },
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          }
        })
        .catch(() => {});
    },
    reset() {
     this.search = ''
	 this.startTime = ''
	 this.endTime = ''
	 this.nextDate = ''
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
	exportList() {
	  var that = this;
	  let patienceName = "";
	  let patienceLinkTel = "";
	  if(this.select==1){
	  		 patienceName = this.search
	  }else{
	  		 patienceLinkTel = this.search
	  }
	  let link = document.createElement("a");
	  const ids = [];
	  this.dataListSelections.forEach((v) => {
	    ids.push(v.id);
	  });
	  const id = ids.join(`,`);
	  link.href =
	     this.$global.baseURL +
	    "/followUp/downloadPendingFollowUps?deptId=" +JSON.parse( localStorage.getItem("userInfo")).deptId+'&patienceName='
		+patienceName+'&patienceLinkTel='+patienceLinkTel+'&startTime='+that.startTime+'&endTime='+that.endTime
		+'&teamsId='+JSON.parse(localStorage.currentTeam).id+'&ids='+id
	
	  link.click();
	},
    getDataList() {
      let that = this;
     let teamsId = "";
     let enertyId = "";
	 let patienceName = "";
	 let patienceLinkTel = "";
	 if(this.select==1){
		 patienceName = this.search
	 }else{
		 patienceLinkTel = this.search
	 }
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {
		  "stringParam1":patienceName, 
		  "stringParam2":patienceLinkTel, 
		  "stringParam3":that.startTime,
		  "stringParam4":that.endTime,
		  "intParam3":that.nextDate,
		  "stringParam5":teamsId,
		  "intParam1":that.pageSize,
		  "intParam2":that.pageNo,
	  }

 

      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/followUp/getPendingFollowUps"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            data.data.forEach((val) => {
              if (val.patienceIdCard) {
                val.patience_sex_name = idCardFunction(val.patienceIdCard, 2);
              }
              if (val.patienceIdCard) {
                val.patience_age = idCardFunction(val.patienceIdCard, 3);
              }
            });
            that.tabData = data.list;
            that.dataList = data.data;
            that.oldList = data.data;
            that.dataListLoading = false;
            that.totalPage = data.totalCount;
          }
        });
    },
    getPorjType() {
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=主要诊断"),
        method: "post",
		data: {stringParam1: '主要诊断'},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleClick(row) {
      if (row.patience_sex_name == "男") {
        row.patience_sex = 1;
      } else {
        row.patience_sex = 2;
      }
      localStorage.patientInfo = JSON.stringify(row);
      console.log(row);
      console.log(555, row);

      this.$router.push({
        name: "case-details",
        params: row,
      });
    },
    handleClickTwo() {
      this.$router.push("case/create");
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic {
  position: relative;

  // .el-form {
  //   display: flex;

  //   .el-form-item {
  //     display: flex;
  //     margin-right: 48px;
  //     white-space: nowrap;

  //     .el-form-item__content {
  //       width: 240px;
  //       height: 36px;
  //       border-radius: 4px;
  //       border: 1px solid #d9d9d9;
  //     }
  //   }
  // }

  .button {
    // float: left;

    // .el-button {
    // 	padding: 8px 23px;
    // 	height: 36px;
    // 	background: #0099ff;
    // 	border-radius: 2px;
    // }
  }

  // .el-table {
  //   position: absolute;
  //   top: 144px;
  // }
  // .el-pagination {
  //   margin-top: 371px;
  //   .el-pager .number {
  //     width: 32px;
  //     height: 32px;
  //     border-radius: 2px;
  //     border: 1px solid #d9d9d9;
  //   }
  //   .number:hover {
  //     background-color: #0099ff;
  //   }
  // }
}
</style>